@use '@angular/material' as mat;
@import './variables';

//
// Material styling overrides file for any global material styling needed outside of theming.  Most of the styling
// for material requires a greater specificity in the scope of the style in order to override the material style.
// In other words, if a style uses the same selector as a default material style, the undesired material style
// would be applied because of the order of imports (after styles.scss).
//

button .mat-icon.mat-icon-inline {
  font-size: unset;
  height: unset;
  line-height: unset;
  width: unset;
}

// Disable background shading on inputs.
mat-form-field .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
}

// Force disabled buttons to white text.
button.mat-mdc-raised-button[disabled],
button.mat-mdc-raised-button.mat-mdc-button-disabled {
  color: rgb(256, 256, 256, 0.6);
}

mat-sidenav.mat-drawer {
  background-color: $accent;
  color: white;

  & button.nav-button {
    background-color: $accent;
    color: white;

    border-radius: 8px;

    & :hover {
      border-radius: 8px;

      & span.mat-mdc-button-ripple {
        border-radius: 8px;
      }
    }

    &.active {
      background-color: $primary;
    }

    & span.mat-mdc-button-persistent-ripple {
      border-radius: 8px;
    }
  }
}

// Address issue with width limitations in the vitals.
.mat-mdc-form-field.blood-pressure label {
  max-width: 68px;
}

// Apply a panelClass style of 'fit-content' to any Material select that should expand the content if wider than
// the actual select element.
.fit-content.mat-mdc-select-panel {
  min-width: fit-content;

  .mdc-list-item__primary-text {
    white-space: nowrap;
  }
}

// By default, mat-tab-group will take up 100% of width.  This changes that default to give a float left feel.
mat-tab-group.mat-mdc-tab-group {
  margin-bottom: 10px;
}

// Table defaults.
td.mdc-data-table__cell,
th.mdc-data-table__header-cell {
  padding: 0 4px 0 4px;
}

mat-header-row {
  mat-header-cell:first-of-type,
  mat-footer-cell:first-of-type {
    padding: 0;
  }

  mat-header-cell.mat-mdc-header-cell {
    overflow-wrap: normal;
  }
}

mat-row {
  mat-cell:first-of-type {
    padding: 0;
  }
}

mat-footer-row {
  mat-footer-cell:first-of-type {
    padding: 0;
  }
}
