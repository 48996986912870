@use '@angular/material' as mat;
@import './_variables';

@include mat.core();

// Set letter-spacing to "normal" by default (when applied).
$default-typography-level: mat.define-typography-level(inherit, inherit, inherit, inherit, normal);

$default-typography: mat.define-typography-config(
  $font-family: 'MessinaSans, sans-serif',
  $button: $default-typography-level,
);

$theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
    typography: $default-typography,
  )
);

$beta-theme: mat.define-light-theme(
  (
    color: (
      primary: $beta-primary,
      accent: $beta-accent-palette,
      warn: $app-warn,
    ),
    typography: $default-typography,
  )
);

$staging-theme: mat.define-light-theme(
  (
    color: (
      primary: $staging-primary,
      accent: $staging-accent-palette,
      warn: $app-warn,
    ),
    typography: $default-typography,
  )
);

@include mat.all-component-themes($theme);

.beta-theme {
  @include mat.all-component-colors($beta-theme);
}

.staging-theme {
  @include mat.all-component-colors($staging-theme);
}
