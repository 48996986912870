/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import './assets/styles/material-override';
@import './assets/styles/variables';
@import './assets/styles/theme';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/reboot';
// Import Bootstrap Utilities https://getbootstrap.com/docs/4.1/utilities/
@import 'bootstrap/scss/utilities';
// Get Rid of Bootstrap stuff we don't like
@import './assets/styles/reset';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import './mono-font.scss';
@import './assets/styles/font';

html,
body,
p,
.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-fab,
.mat-mdc-mini-fab,
.mat-mdc-table,
.mat-mdc-paginator,
.mat-mdc-card-title {
  font-weight: 400;
}

h1,
h2,
h3,
h4,
p {
  font-weight: 300;
}

.text-italic {
  font-style: italic;
}

.purple-button {
  color: white;
  padding: 12px;
  border-radius: 6px;
  background-color: $purple;
}

.chat-link-url {
  color: white;
  text-decoration: underline;
}

// STYLES
html,
body {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
}

button.custom-icon {
  border: none;
  background-color: transparent;
}

img.custom-icon {
  margin-top: -10px;
}

.purple-icon {
  color: #4f4998; // Lilac100
}

.background-primary {
  background-color: mat.get-color-from-palette($app-primary);
  color: mat.get-color-from-palette($app-primary, '800-contrast') !important;
}

mat-snack-bar-container.background-primary .mdc-snackbar__surface {
  background-color: mat.get-color-from-palette($app-primary);
  color: mat.get-color-from-palette($app-primary, '800-contrast') !important;
}

.background-accent {
  background-color: mat.get-color-from-palette($app-accent);
  color: mat.get-color-from-palette($app-accent, '200-contrast');
}

.background-accent-hint {
  background-color: mat.get-color-from-palette($app-accent, 50);
}

mat-snack-bar-container.background-warn .mdc-snackbar__surface {
  background-color: mat.get-color-from-palette($app-warn);
  color: mat.get-color-from-palette($app-warn, '700-contrast');
}

.background-red {
  color: #cc5447 !important;
  background-color: #ffe1de !important;
}

.background-orange {
  color: #cc753e !important;
  background-color: #ffe9db !important;
}

.background-green {
  color: #529412 !important;
  background-color: #e1f1d0 !important;
}

video {
  max-width: 100%;
  max-height: 100%;
}

.width-100 {
  width: 100%;
}

.width-96 {
  width: 96% !important;
}

.height-100 {
  height: 100%;
}

.color-warn,
.mat-text-warn {
  color: $warn;
}

.info-bar {
  padding: 12px 24px 0;
  width: 100%;
}

.full-width {
  width: 100%;
}

.text-center {
  text-align: center;
}

.patient-search--container {
  display: inline-block;
  margin-right: 15px;

  .mat-mdc-progress-spinner circle {
    stroke: #fff;
  }

  // Input text color.
  mat-form-field .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: white;

    --mat-select-placeholder-text-color: rgb(256, 256, 256, 0.6);
    --mat-form-field-disabled-input-text-placeholder-color: rgb(256, 256, 256, 0.6);
    --mdc-filled-text-field-input-text-placeholder-color: rgb(256, 256, 256, 0.6);
    --mdc-outlined-text-field-input-text-placeholder-color: rgb(256, 256, 256, 0.6);
  }

  // Input text underline
  mat-form-field .mat-mdc-form-field-subscript-wrapper {
    height: 1px;
    background-color: white;
  }
}

.multi-line-option {
  .mat-line {
    line-height: 1.2em;
  }

  .mat-line:last-child {
    font-size: 13px;
    margin-top: 2px;
  }

  &.mat-3-line {
    font-size: 16px;
  }
}

.sidenav-closed--content {
  margin-left: 60px !important;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.mono-font {
  font-family: 'Roboto Mono', monospace;
}

// The monospace font appears too low in the printed PDF. This raises up the monospace 0s by a small amount only when printing to appear in-line with other characters
.pdf-style {
  .mono-font {
    position: relative;
    top: -0.75px;
  }
}

.mat-mdc-row.table-row-read {
  background-color: #fafafa;
}

.mat-mdc-row.table-row-unread {
  background-color: #fff;
  font-weight: bold;
}

.no-padding .mat-mdc-dialog-container {
  padding-top: 0;
  padding-bottom: 0;
}

// Referral form

.referral-form__label {
  display: block;
  text-transform: uppercase;
  color: $darkGrey;
  font-size: 11px;
  margin-bottom: 0;
}

.referral-form__table-container {
  width: 100%;
  border: 1px $lightGrey solid;
  border-radius: 4px;
  margin-bottom: 24px;
  padding: 18px;

  table {
    width: 100%;
    margin-bottom: 12px;

    tr {
      border-bottom: 1px #e4e4e4 solid;
    }

    tr.alternate-highlight {
      background-color: #f4f4f4;
    }
  }

  .mat-mdc-form-field {
    width: 100%;

    textarea {
      width: 100%;
    }
  }
}

.mat-badge-no-grow {
  .mat-badge-content {
    transform: none;
    transition: none;
  }
}

.referral-form__table-header {
  display: block;
  text-transform: uppercase;
  color: $darkGrey;
  font-size: 11px;
  font-weight: normal;
  padding: 6px;
}

.referral-form__table-cell {
  padding: 6px;
}

.referral-form__no-data {
  margin-top: 14px;
  font-style: italic;
  text-align: center;
}

.inactive-notification-dialog-backdrop {
  background-color: #3e3e3e;
  opacity: 0.9 !important;
}

.icon-green {
  color: $verifiedGreen;
}

.icon-gray {
  color: gray;
}

.icon-white {
  color: white;
}

.icon-warn {
  color: $warn;
}

.wrapping-tooltip {
  white-space: pre-line;
}
.wrap {
  white-space: pre-line;
}
.white-spinner circle {
  stroke: rgba(255, 255, 255, 0.6) !important;
}

.chat-link-email {
  color: white !important;
}
