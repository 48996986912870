@use '@angular/material' as mat;

// colors
$white: #fff;
$black: #000;
$grey: #78849e;
$lightGrey: #b4b4c6;
$darkGrey: #78849e;
$purple: #26185c;
$accent: #4f4898;
$background: #f5f5ff;
$teal: #25889f;

$secondary: #6c757d;
$mediumGrey: #8b8b8b;
$vitalsGrey: #c9c9c9;
$verifiedGreen: #68b916;
$warnDark: #ac5b5b;

$mat-nicepurple: (
  50: #e5e3eb,
  100: #bebace,
  200: #938cae,
  300: #675d8d,
  400: #473b74,
  500: #26185c,
  600: #221554,
  700: #1c114a,
  800: #170e41,
  900: #0d0830,
  A100: #796aff,
  A200: #4b37ff,
  A400: #1d04ff,
  A700: #1700ea,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$mat-nicepurplelite: (
  50: #eae9f3,
  100: #cac8e0,
  200: #a7a4cc,
  300: #847fb7,
  400: #6963a7,
  500: #4f4898,
  600: #484190,
  700: #3f3885,
  800: #36307b,
  900: #26216a,
  A100: #afaaff,
  A200: #7f77ff,
  A400: #4f44ff,
  A700: #372aff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$mat-nicewarn: (
  50: #ffedeb,
  100: #ffd2cd,
  200: #ffb4ac,
  300: #ff968b,
  400: #ff8072,
  500: #ff6959,
  600: #ff6151,
  700: #ff5648,
  800: #ff4c3e,
  900: #ff3b2e,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffdcda,
  A700: #ffc3c0,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-niceaffirm: (
  50: #f6f7e3,
  100: #e8eab9,
  200: #d9dc8b,
  300: #c9ce5c,
  400: #bec439,
  500: #b2b916,
  600: #abb213,
  700: #a2aa10,
  800: #99a20c,
  900: #8a9306,
  A100: #faffbf,
  A200: #f6ff8c,
  A400: #f3ff59,
  A700: #f1ff40,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$mat-gold: (
  50: #fff8e1,
  100: #ffecb3,
  200: #ffe082,
  300: #ffd54f,
  400: #ffca28,
  500: #ffc107,
  600: #ffb300,
  700: #ffa000,
  800: #ff8f00,
  900: #ff6f00,
  A100: #ffe57f,
  A200: #ffd740,
  A400: #ffc400,
  A700: #ffab00,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$green-palette: (
  50: #e5eae3,
  100: #becab8,
  200: #93a789,
  300: #68835a,
  400: #476936,
  500: #274e13,
  600: #234711,
  700: #1d3d0e,
  800: #17350b,
  900: #0e2506,
  A100: #7dff61,
  A200: #53ff2e,
  A400: #2cfa00,
  A700: #27e100,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$app-primary: mat.define-palette($mat-nicepurple, 500, 300, 700);
$app-accent: mat.define-palette($mat-nicepurplelite, 500, 300, 700);
$app-warn: mat.define-palette($mat-nicewarn, 500);
$app-affirm: mat.define-palette($mat-niceaffirm, 500);
$app-gold: mat.define-palette($mat-gold, 500, 50, 700);

$beta-primary: mat.define-palette($green-palette, 500);
$beta-accent-palette: mat.define-palette($green-palette, 300);
$beta-accent-palette-color: mat.get-color-from-palette($beta-accent-palette);

$staging-primary: mat.define-palette($mat-gold, 900);
$staging-accent-palette: mat.define-palette($mat-gold, 600);
$staging-accent-palette-color: mat.get-color-from-palette($staging-accent-palette);

$primary: mat.get-color-from-palette($app-primary);
$accent: mat.get-color-from-palette($app-accent);
$warn: mat.get-color-from-palette($app-warn);
$affirm: mat.get-color-from-palette($app-affirm);
$gold: mat.get-color-from-palette($app-gold);

$warn-light: #ffedeb;

$background: #ffffff;
$background-dark: #fafafa;

$link-color: $primary;
$link-hover-color: currentColor;
$link-hover-decoration: none;

//
//
//
.mat-gold {
  background-color: mat.get-color-from-palette($app-gold) !important;
  color: mat.get-color-from-palette($app-gold, '800-contrast') !important;
}

.mat-gold[disabled] {
  background-color: mat.get-color-from-palette($mat-gold, 100) !important;
  color: mat.get-color-from-palette($app-gold, '100-contrast') !important;
}

.mat-affirm {
  background-color: mat.get-color-from-palette($mat-niceaffirm, 700) !important;
  color: mat.get-color-from-palette($app-affirm, '800-contrast') !important;
}

.mat-affirm[disabled] {
  background-color: mat.get-color-from-palette($mat-niceaffirm, 100) !important;
  color: mat.get-color-from-palette($mat-niceaffirm, 900) !important;
}
